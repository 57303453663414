<template>
    <section id="content">
        <div class="container">
            <div class="card">
                <div class="card-body card-padding">
                    <div class="contacts clearfix row" v-if="getSearchResults.length > 0">
                        <div class="col-md-3 col-sm-4 col-xs-6" v-for="worker in getSearchResults" :key="worker.id">
                            <div class="c-item">
                                <router-link :to="'/staff/' + worker.id" class="ci-avatar">
                                    <VueLoadImage>
                                        <img slot="image" :src="worker.photo != '' ? worker.photo : 'img/placeholder.png'"/>
                                        <img slot="preloader" src="img/loading.gif"/>
                                        <img slot="error" src="img/placeholder.png"/>
                                    </VueLoadImage>
                                </router-link>
                                <div class="c-info">
                                    <strong>{{ worker.name }}</strong>
                                    <small>{{ worker.email }}</small>
                                </div>
                                <div class="c-footer">
                                    <button class="waves-effect" @click="viewStaff(worker.id)">
                                        <i class="zmdi zmdi-person-add"></i> 
                                        VIEW
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="jombotron" v-else>No results found</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import VueLoadImage from 'vue-load-image'
    export default {
        data(){
            return {
                staff: this.$store.state.staffSearchResults,
            }
        },
        computed: {
            getSearchResults(){
                return this.$store.state.staffSearchResults
            }
        },
        methods: {
            addStaff(){
                this.$router.push("/add")
            },
            viewStaff(staffId){
                this.$router.push("/staff/" + staffId)
            },            
        },
        components: {
            VueLoadImage,
        },
    }
</script>